<template>
  <div style="width:350px;">
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="原密码" prop="oldpass">
        <el-input type="password" v-model="ruleForm.oldpass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newpass">
        <el-input type="password" v-model="ruleForm.newpass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="checkPass">
        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save">确定</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    var checkoldpass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入旧密码'))
      } else {
        this.$http.post('/user/CheckPwd',null ,{params: {userid: this.$store.state.userid, pwd: value}}).then(res => {
          if (res.data.code != 200) {
            callback(new Error('原密码错误'))
          }else{
            callback()
          }
        })
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.newpass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        oldpass: '',
        newpass: '',
        checkPass: ''
      },
      rules: {
        oldpass:[{validator:checkoldpass,trigger: 'blur'}],
        newpass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
        
      }
    }
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    save(){
      this.$http.post('/user/ChangePwd',null, {params: {userid: this.$store.state.userid, newpwd: this.ruleForm.newpass}} ).then(res=>{
        if (res.data.code != 200) {
            this.$message.error(res.data.errmsg)
          } else {
            this.$message({
              message: '修改成功！',
              type: 'success'
            })
            this.$router.push('/login')
          }
      }).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
    }
  }
}
</script>